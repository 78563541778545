const quotes = [
  "Es ist nie zu spät, für das zu kämpfen, was einem wirklich wichtig ist",
  "Nichts ist falsch, wenn dein Herz dir sagt, dass es richtig ist",
  "Eine ruhige See hat noch nie einen geschickten Seemann gemacht (Roosevelt)",
  "Familie ist wie ein Baum. Die Zweige mögen in unterschiedliche Richtungen wachsen, doch die Wurzeln halten alles zusammen!",
  "Den Liebenden mundet Wasser wie Wein",
  "Wenn die Steine schwimmen, versinken die Blätter (aus Japan)",
  "Auch aus Steinen, die einem in den Weg gelegt werden, kann man Schönes bauen (Johann Wolfgang von Goethe)",
  "Es gibt wenig aufrichtige Freunde - die Nachfrage ist auch gering (Marie von Ebner-Eschenbach)",
  "Dumme rennen, Kluge warten, Weise gehen in den Garten",
  "Der Kluge ärgert sich über die Dummheiten, der Weise belächelt sie",
  "Das einzige Mittel, Zeit zu haben, ist, sich Zeit zu nehmen",
  "Wer die Gegenwart genießt, hat in Zukunft eine wunderbare Vergangenheit",
  "Welches auch die Gaben sein mögen, mit denen du erfreuen kannst, erfreue (Ovid)",
  "Der Tropfen Geduld kann mehr erreichen als ein Ozean des Zorns",
  "In der Liebe sprechen Hände und Augen meist lauter als der Mund",
  "Mut steht am Anfang des Handelns, Glück am Ende (Demokrit)",
  "Es geht nicht darum, was unter dem Christbaum steht, sondern wer darum steht",
  "Manchmal möchte ich dieses Erwachsensein hinschmeißen und einfach schaukeln gehen",
  "Du siehst nicht wirklich die Welt, wenn du nur durch dein eigenes Fenster siehst (aus der Ukraine)",
  "Beobachte das Schwimmen der Fische im Wasser und du wirst den Flug der Vögel in der Luft begreifen (Leonardo da Vinci)",
  "Ich kann auf ein bewegtes Leben zurückblicken, sagte die Marionette (Werner Mitsch)",
  "Man sollte nicht schneller fahren, als der Schutzengel fliegen kann (Peter Ustinov)",
  "Das Wichtigste, wenn Sie sich in einem Loch wiederfinden, ist es, aufzuhören zu graben (Warren Buffett)",
  "Im Grunde sind es immer die Verbindungen mit Menschen, die dem Leben seinen Wert geben (Wilhelm von Humboldt)"
]

module.exports = quotes;